import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps, Link } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import { Layout } from "../components/Layout/index.tsx";
import PortableText from "@sanity/block-content-to-react";
import SanityImage from "gatsby-plugin-sanity-image";
import { MdLink } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";
import { convertDate } from "../helpers/convertDate";
import Tag from "../components/ui/Tag";
import { useViewport } from "../hooks/useViewport";
import { isViewport } from "../helpers";
import ButtonNormal from "../components/ui/ButtonNormal";
import HorizontalRule from "../components/content/HorizontalRule";

const Container = styled.div`
    ${tw`flex flex-col items-center md:mx-10 mx-4 bg-white`};
    h1 {
      ${tw`text-5xl`}
      margin-top:1rem;
      @media only screen and (max-width: 768px) {
        ${tw`text-3xl`}
        letter-spacing: -0.06rem;
      }
    }
`;

const StyledLink = styled(Link)`
    ${tw`md:(w-1/2 px-0 mx-auto) px-6`}
    color: #808dff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 1rem;
    margin-bottom: 2rem;
`;

const StyledSanityImage = styled(SanityImage)`
    width: 100%;
    height: 100%;
`;

const WrapperDesk = styled.div`
    background-color: black;
`

const StyledSanityImageDesk = styled(SanityImage)`
    mask-image: linear-gradient(37deg, rgba(30, 30, 50, 0) -45.42%, #1E1E32 66.92%);;
    width: 100%;
    height: 100%;
`;

const StyledSanityImageSubSectionWrapper = styled.div`
    max-height: 50vh;
    overflow: hidden;
`;

const StyledSanityImageSubSection = styled(SanityImage)`
    width: 100%;
    height: auto;
`;

const StyledPortableText = styled(PortableText)`
    text-align: left;
    ${tw`flex flex-col mx-auto`}
    margin-top: 7%;
    margin-bottom: 7%;
    p, h1, h2, h3, h4, ul, ol {
        max-width: 80vw;
        ${tw`md:(w-1/2 px-0 mx-auto) px-6`}
    }
    > div {
        ${tw`md:(w-1/2 px-0 mx-auto mb-10)`}
    }
    li {
        ${tw`ml-4`}
    }
    ol {
        ${tw`mb-10 mt-0`}
    }
`;

const StyledPortableTextSubSection = styled(PortableText)`
    ${tw`flex flex-col mt-24 pb-24`}
    p, h1, h2, h3, h4, ul {
        max-width: 80vw;
        ${tw`md:(w-1/2 px-0 mx-auto) px-6`}
    }
    li {
        ${tw`ml-4`}
    }
`;

const AuthorImage = styled(SanityImage)`
    width: 136px;
    height: 136px;
    border-radius: 100%;
    ${tw`mr-8 mb-4`}
`;

const InitialsDot = styled.div`
    background-color: #a8a8bd;
    color: #fff9f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 59px;
    height: 59px;
    ${tw`mr-8`}
    p {
        font-size: 24px;
        margin: 0;
    }
`;

const AuthorSection = styled.div`
    max-width: 1080px;
    ${tw`mx-auto md:w-full flex flex-col md:(flex-row px-0) px-6 justify-between py-24`}
    p {
        max-width: 24rem;
    }
`;
const LineWrapper = styled.div`
    width: 100%;
    max-width: 1080px;
`
const Line = styled.div`
    ${tw`md:mx-0 mx-4 bg-quickSand`}
    height: 2px;
`;
const Card = styled.div`
    width: 100%;
    overflow: hidden;
    img {
        width: auto;
        ${tw`mb-8`}
    }
    a {
        ${tw`text-quickDark`}
    }
    a:visited {
        ${tw`text-quickDark`}
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

const CardTitle = styled.h1`
    ${tw`mt-0 mb-3 px-6`}
`;

const HorizontalRuleWrapper = styled.div`
    ${tw`mb-10`}
    div {
        margin-left: 0;
        margin-right: 0;
    }
`

const Article: React.FC<PageProps<any>> = ({
    data: { sanityArticle },
    ...props
}) => {
    const {
        slug,
        title,
        date,
        downloads,
        image,
        author,
        country,
        tags,
        _rawText,
        metaData,
    } = sanityArticle;
    // const url = linkResolver({ _type, slug });

    const viewport = useViewport();
    const isPhone = isViewport(viewport, ["PHONE_ONLY", "TABLET_PORTRAIT_UP"]);

    const serializers = {
        types: {
            internalLink: ({ node }) => {
                return (
                    <div>
                        <StyledLink to={"/" + node.page?.slug.current}>
                            <span>{node.linkText}</span>
                            <MdChevronRight size={36} color="#808DFF" />
                        </StyledLink>
                    </div>
                );
            },
            subArticle: ({ node }) => {
                const { backgroundColor } = node;
                return (
                    <div
                        tw="relative left-0 w-full mb-24"
                        style={{ backgroundColor: backgroundColor?.hexcode }}
                    >
                        <StyledSanityImageSubSectionWrapper>
                            <StyledSanityImageSubSection
                                {...node.image.image}
                                alt={node.image.title}
                            ></StyledSanityImageSubSection>
                        </StyledSanityImageSubSectionWrapper>
                        <StyledPortableTextSubSection blocks={node.text} />
                    </div>
                );
            },
            buttons: ({ node }) => {
                return (
                    <div tw="flex md:mr-4 mb-10 flex-1 md:flex-row flex-col lg:(justify-start) justify-between">
                        {node.buttons.map((button, index) => {
                            return (
                                <div tw="md:(mr-4 px-0) px-6">
                                    <ButtonNormal
                                        tw="md:mr-4"
                                        button={button}
                                        key={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            },
            horizontalRule: ({ node }) => {
                return (
                    <HorizontalRuleWrapper>
                        <HorizontalRule block={{color: node.color}}/>   
                    </HorizontalRuleWrapper>
                );
            },
            imageWithTitle: ({ node }) => {
                return (
                <div tw="md:px-0 px-6 mb-10">
                    <StyledSanityImage
                        {...node.image}
                        alt={node.title}
                    />
                    <div style={{marginTop: "1rem"}}>
                        <span style={{fontStyle: "italic", opacity: .5}}>{node.title}</span>
                    </div>
                </div>
                )
            },

        },
    };

    const meta = metaData !== null ? metaData : {
        metaTitle: title,
        metaDescription: null,
        metaRobots: true,
    }

    const pageTitle = meta.metaTitle + " | Quickorder";

    const translations = {
        aboutTheAuthor: {
            en: "About the author",
            da: "Skrevet af",
            no: "Skrevet av"
        }
    }

    return (
        <Layout language={country}>
            <Helmet>
                <title>{pageTitle}</title>
                {meta.metaDescription !== null &&
                <meta name="description" content={meta.metaDescription}></meta>}
                <meta name="robots" content={meta.metaRobots === false ? "noindex" : ""}></meta>
            </Helmet>
            <Container>
                {isPhone ? (
                    <Card>
                        <Wrapper>
                            <StyledSanityImage
                                {...image.image}
                                alt={image.title}
                            ></StyledSanityImage>
                            <div
                                tw="px-6"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Tag color="#808DFF" backgroundColor="#ECEEFF">
                                    {tags[0]}
                                </Tag>
                                <span style={{ marginLeft: "1rem" }}>
                                    {convertDate(date, country)}
                                </span>
                            </div>
                        </Wrapper>
                        <CardTitle>{title}</CardTitle>
                    </Card>
                ) : (
                    <div tw="relative h-auto w-full">
                        <WrapperDesk>

                        <StyledSanityImageDesk
                            {...image.image}
                            alt={image.title}
                        ></StyledSanityImageDesk>
                        </WrapperDesk>

                        <div tw="flex absolute bottom-8 left-16 flex-col">
                            <div tw="flex items-center">
                                <Tag backgroundColor="white" color="#1E1E32">
                                    {tags[0]}
                                </Tag>
                                <span tw="text-quickSandLighter ml-4">
                                    {convertDate(date, country)}
                                </span>
                            </div>
                            <h1 tw="text-quickSandLighter max-w-3xl">
                                {title}
                            </h1>
                        </div>
                    </div>
                )}
                <StyledPortableText
                    blocks={_rawText}
                    serializers={serializers}
                />
                <LineWrapper>
                    <Line />
                </LineWrapper>
                <AuthorSection>
                    <div tw="flex flex-col md:(flex-row mb-0) mb-10">
                        {author.photo ? (
                            <AuthorImage 
                                {...author.photo}
                                alt={author.initials}
                            >
                            </AuthorImage>) 
                        : (
                            <InitialsDot>
                                <p>{author.initials}</p>
                            </InitialsDot>)}
                        <div>
                            <h5>Written by {author.name}</h5>
                            <p>{author.bio}</p>
                            <div style={{ display: "flex" }}>
                                {tags.map((tag, index) => {
                                    return (
                                        <div tw="mr-4" key={index}>
                                            <Tag
                                                color="#808DFF"
                                                backgroundColor="#808DFF26"
                                            >
                                                {tag}
                                            </Tag>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {downloads[0] && (
                        <div>
                            <p tw="font-bold">Downloads</p>
                            {downloads.map((download, index) => {
                                return (
                                    <a
                                        tw="text-quickDark"
                                        href={download.externalLink ? download.externalLink : download.file?.asset.url}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        <div tw="flex items-center">
                                            <MdLink tw="mr-2" size={24} />
                                            <p tw="mb-0">{download.title}</p>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    )}
                </AuthorSection>
            </Container>
        </Layout>
    );
};

export default Article;

// maintain: Here you have to add a new UI component
export const query = graphql`
    query Article($slug: String!) {
        sanityArticle(slug: { current: { eq: $slug } }) {
            metaData {
                metaTitle
                metaDescription
                metaRobots
            }
            slug {
                current
            }
            title
            _rawText(resolveReferences: { maxDepth: 10 })
            country
            tags
            image {
                _key
                _type
                image {
                    ...ImageWithPreview
                }
                title
            }
            date
            author {
                _id
                _key
                bio
                initials
                name
                photo {
                    asset {
                        _id
                    }
                }
            }
            downloads {
                file {
                    asset {
                        url
                    }
                }
                title
                externalLink
            }
        }
    }
`;
